.shop{
    margin-top: 170px;
}
.shop .footFix{
    margin-top: 250px;
    font-size: 30px;
    margin-bottom: 900px;
    margin-left: 50px;
}
.shop .prices{
    margin-top: 220px;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}
.shop .prices .reg{
    margin-right: 10px;
    width: 400px;
    height: 220.3px;
}
.shop .prices .oth{
    margin-left: 10px;
    width: 400px;
    height: 165.79px;
}

@media screen and (min-width: 1237.5px){

    .shop .prices .oth{
        width: 600px;
        height: 248.68px;
    }
    .shop .prices .reg{
        width: 600px;
        height: 330.46px;
    }


}

@media screen and (max-width: 836.25px){

    .shop .prices .reg{
        margin-right: 0px;
    }
    .shop .prices .oth{
        margin-left: 0px;
    }
    .shop .prices img{
        width: 90%;
    }



}

@media screen and (max-width: 410px){

    .shop .prices .reg{
        width: 300px;
        height:165px
    }
    .shop .prices .oth{
        width: 300px;
        height: 123.75px;
    }




}
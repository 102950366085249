.navbar{
    white-space: nowrap;
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 190px;
    position: absolute;
    top:0;
    left:0;

}

.navbar span:hover {
    cursor: pointer;
    color: rgb(93, 93, 93);
}
.navbar .logo{
    position: absolute;
    height: 140px;
    top: 5px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

}

.navbar .links{
    position: absolute;
    display: flex;
    flex: max-content;
    width: 100%;
    height: 40px;
    background-color: rgb(255, 205, 115);
    top: 150px;
    white-space: nowrap;
    list-style-type: none;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    border-top: 2px solid black;
    border-bottom: 2px solid black;

}
.navbar .links li{
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
}
.navbar .btn2{
    display:block;
    width: 100%;
    padding: 10px 0;
}

.navbar .links li:hover{
    background-color:rgb(255, 228, 178);
}

.navbar .viewCartLong {
    position: absolute;
    height: 150px;
    width: 140px;
    margin: 0;
    margin-left: 20px;
    top: 0;
    right: 0;
    border: none;
    border-left: black 1px solid;
    background-color: rgba(255, 205, 115);
    font-size: 20px;

}
.navbar .viewCartLong:hover {
    position: absolute;
    height: 150px;
    width: 140px;
    margin: 0;
    margin-left: 20px;
    top: 0;
    right: 0;
    border: none;
    border-left: black 1px solid;
    background-color: rgb(255, 228, 178);
    font-size: 20px;

}

.navbar .viewCartShort, .navbar .viewCartShortCont{
    display:none
}


.navbar .viewCartShort{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width: 100%;
    height:100%;
    background-color: (255, 205, 115);
}
.navbar .viewCartShort:hover{
    background-color: rgb(255, 228, 178);
}
.navbar .viewCart:hover{
    background-color:rgb(255, 228, 178);
}


.navbar .links .curr{
    background-color: rgb(255, 228, 178);
    padding: 8px 0;
}


@media screen and (max-width: 665px){
    .navbar .viewCartShort, .navbar .viewCartShortCont{
        display: inline;
    
    }
    .navbar .viewCartLong {
        display: none;
    }
}


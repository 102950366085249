.login{
    width: 400px;
    background-color: rgb(255, 205, 115);
    margin: 0 auto;
    margin-top: 300px;
    height: 400px;
    padding: 10px 0;
    border-radius: 5px;
}
.login h2 {
    text-align: center;
    font-size: 40px; 
}

.login ul {
    margin: 0;
    padding: 0;
    margin-top: 80px;
}
.login li {
    margin-top: 40px;
    list-style-type: none;
    text-align: center;
}
.login input {
    width: 50%;
    height: 30px
}

.login button{
    margin-left:55px;
    margin-top: 40px;
    padding: 10px 20px;
}

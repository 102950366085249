.featureList {
  display: flex;
  justify-content: center;
}
.featureList .spacing {
  width: 100%;
}
.featureList .itemLis {
  width: 1650px;
}
.featureList a {
  display: block;
  margin: 10px;
  background-color: rgba(254, 254, 254, 0.8);
  padding: 10px;
  border-radius: 4px;
  height: 400px;
  width: 300px;
}
.featureList a:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.featureList a img {
  border: black solid 2px;
  width: 300px;
  height: 200px;
  object-fit: contain;
  border-radius: 4px;
  background-color: white;
}

.featureList h2 {
  margin-bottom: 0;
  font-size: 1em;
  width: 302px;
  margin-top: 0;
}
.featureList .price {
  margin-top: 0;
}

.featureList .itemInfo {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}
.featureList .itemInfo p {
  margin: 0;
  padding: 0;
}
.featureList .itemTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


@media screen and (max-width: 100000px) {
  .featureList .itemLis {
    width: 1320px;
  }
}
@media screen and (max-width: 1400px) {
  .featureList .itemLis {
    width: 990px;
  }
}
@media screen and (max-width: 1030px) {
  .featureList .itemLis {
    width: 660px;
  }
}
@media screen and (max-width: 700px) {
  .featureList .itemLis {
    width: 330px;
  }
}

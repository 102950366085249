.emailHookCheckout form {
  margin: 0;
}
.emailHookCheckout div {
  margin: 20px 0;
  list-style-type: none;
}
.emailHookCheckout .nameE {
  flex-wrap: none;
}
.emailHookCheckout input {
  background-color: rgba(255, 222, 162);
  width: 200px;
  height: 30px;
  border: black 2px solid;
  font-size: 20px;
}
.emailHookCheckout button {
  background-color: rgba(255, 222, 162);
  width: 200px;
  height: 30px;
  border: black 2px solid;
  font-size: 20px;
}
.emailHookCheckout input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}
.emailHookCheckout .comment::placeholder {
  color: rgba(0, 0, 0, 0.7);
}
.emailHookCheckout .comment {
  background-color: rgba(255, 222, 162);
  width: 449px;
  height: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 4px;
  resize: none;
  font-size: 20px;
  font-family: inherit;
}
.emailHookCheckout .fullLine {
  width: 439px;
}
#subbut {
  height: 40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 605px) {
  /*  conact me stuff*/
  .emailHookCheckout div {
    margin: 0;
    margin-left: 5%;
    margin-bottom: 20px;
  }
  .emailHookCheckout {
    width: 100%;
  }

  .emailHookCheckout .comment {
    width: 90%;
  }
  .emailHookCheckout .fullLine {
    width: 90%;
  }
  .emailHookCheckout .comment {
    width: calc(90% + 8px);
  }
}


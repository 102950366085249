.success{
  margin: 500px 0;
  display:block;
}
.success h1{
  text-align: center;
}
.success div{
  display:flex;
  justify-content: center;
}

.success p{
  text-align: center;
  margin-bottom: 200px;
  width: 900px;
}


#goB{
  margin-left: 20%;
}
.footer{
    position:absolute;
    margin: 0;
    margin-top: 80px;
    width: 100%;
    height: 500px;
    left: 0;

    background-color: black;
}


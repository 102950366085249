@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: Poppins, sans-serif;
    position: absolute;
    width:100%;
    padding:0;
    margin:0;
    left:0;
    top:0;
}
.btn {
    background: rgb(255, 205, 115);
    text-align: none;
    padding: 8px 12px;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    font-size: 1em;
    border: 2px solid rgb(62, 62, 62);
  }
.btn:hover {
    background: rgb(255, 228, 178);
}

.error {
    color: red;
    background: pink;
    border: 1px solid red;
    border-radius: 4px;
    padding: 8px;
    margin: 10px 0;
  }

a {
    text-decoration: none;
    color: black;
}